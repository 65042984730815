import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { ApiService } from 'src/app/admin-portal/core/services/api.service';
import { environment } from 'src/environment/environment';
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient, private apiservice: ApiService) {}

  uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    let path = `${this.baseUrl}/files/upload`;
    return this.http.post(path, formData);
  }

  getLogo(logoPath: string): Observable<Blob> {
    const apiUrl = `${this.baseUrl}/files/${logoPath}`;
    return this.http.get(apiUrl, { responseType: 'blob' });
  }

  updateToolTip(newAccountTour: boolean, userID: string) {
    const path = `${this.baseUrl}/auth`;
    return this.http.put(path, { newAccountTour, userID });
  }

  createAccount(formData: any): Observable<any> {
    let path = `${this.baseUrl}/account`;
    return this.apiservice.post(path, formData);
  }
  getCountries(): Observable<any> {
    let path = `${this.baseUrl}/master/countries`;
    return this.apiservice.get(path);
  }
  getStates(countryID: number): Observable<any> {
    let path = `${this.baseUrl}/master/states?countryId=${countryID}`;
    return this.apiservice.get(path);
  }

  getCities(stateId: number): Observable<any> {
    let path = `${this.baseUrl}/master/cities?stateId=${stateId}`;
    return this.apiservice.get(path);
  }

  getCurrency(): Observable<any> {
    let path = `${this.baseUrl}/master/currencies`;
    return this.apiservice.get(path);
  }

  getAccountList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: '' | 'ASC' | 'DESC' = ''
  ): Observable<any> {
    const path = `${this.baseUrl}/account/list?page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiservice.get(path);
  }

  getAccountById(id: string): Observable<any> {
    const path = `${this.baseUrl}/account/${id}`;
    return this.apiservice.get(path);
  }

  updateAccount(updatedDataData: any): Observable<any> {
    const url = `${this.baseUrl}/account`;
    return this.apiservice.put(url, updatedDataData).pipe(
      catchError((error: any) => {
        console.error('Error updating expense:', error);
        throw error;
      })
    );
  }

  getTimeZone(): Observable<any> {
    let path = `${this.baseUrl}/master/time-zones`;
    return this.apiservice.get(path);
  }

  getAllActivityLogs(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: '' | 'ASC' | 'DESC' = ''
  ): Observable<any> {
    const path = `${this.baseUrl}/auth/activity-logs?module=account&module=resource&module=cost_center&module=core_team&module=project&module=project_resource&module=re_occurring_expense&page=${pageNumber}&limit=${limit}&search=${search}&sortBy=${sortBy}&orderBy=${orderBy}`;
    return this.apiservice.get(path);
  }
}
